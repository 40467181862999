import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import Link from "../components/link"
import Pagination from "../components/pagination"
import { htmlDecode } from "../Utils"

const AwardsArchiveTemplate = ({ data, pageContext }) => {
  const seoTitle = (data.strapiAwardsPage.seo) ? data.strapiAwardsPage.seo.seoTitle : data.strapiAwardsPage.title
  const seoDesc = (data.strapiAwardsPage.seo) ? data.strapiAwardsPage.seo.seoDescription : ''
  const featuredImageUrl = (data.strapiAwardsPage.featuredImage?.formats?.large) ? data.strapiAwardsPage.featuredImage.formats.large.url : data.strapiAwardsPage.featuredImage.url
  const cdnUrl = 'https://dlyog079eaher.cloudfront.net';

  return (
    <Layout classNames="news-and-press page-template page-template-tpl-news page-template-tpl-news-php">
      <Seo title={ seoTitle } seoTitle={ seoTitle } seoDescription={ seoDesc } />
      <section className="banner-wrap">
        <div id="banner" className="banner">
          <div className="banner-bg-image col-12 ph0 small" style={{backgroundImage: `url(${featuredImageUrl.includes(cdnUrl) ? featuredImageUrl : cdnUrl + featuredImageUrl })`, opacity: 1}}>
          </div>
          <div className="banner-overlay vert-gradient" />
          <div className="banner-inside aic jcfs">
            <div className="col-xs-12 col-sm-10 col-md-8 banner-text m-left text-left blue-overlay vert-gradient">
              <h1 className>{ htmlDecode(data.strapiAwardsPage.title) }</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="content-wrap">
        <div className="tease-items-block pb6">
          <div className="container">
            <div className="row justify-content-center">
              {data.allStrapiNews.edges.map(({ node }) => (
                  <div className="col-12 col-sm-8 col-md-6 col-lg-4 col-xl-4 list-item ph2">
                  <div className="list-content bgw br3 mb4 ofh bs1">
                    {node.featuredImage &&
                      <Link className="news-img-link dbha" href={ node.link } title={ node.title } target="_blank">
                        <div className="list-content_image-wrap brdr1--bottom bcg3" style={{backgroundImage: `url(${node.featuredImage.url.includes(cdnUrl) ? node.featuredImage.url : cdnUrl + node.featuredImage.url})`}} />
                      </Link>
                    }
                    <div className="teaser-text-wrap">
                      {node.publicationDate &&
                        <div>
                          <p className="teaser-subhead-alt-small-light">{ node.publicationDate }</p>
                        </div>
                      }
                      <Link href={ node.link } target="_blank">
                        <h3 className="teaser-header mb2">{ node.title }</h3>
                      </Link>
                      <hr className="small teal mb1.8 ml0" />
                      <p className="copy">{ htmlDecode(node.content).substring(0, 125) }&hellip;</p>
                      <Link className="button uppercase inverse mb1.2" href={ node.link } target="_blank">Open Article</Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {pageContext.totalPages > 1 &&
          <Pagination currentPage={ pageContext.currentPage } totalPages={ pageContext.totalPages } slug="awards" />
        }
      </section>
    </Layout>
  )
}

export default AwardsArchiveTemplate

export const query = graphql`
  query AwardsArchiveTemplate($limit: Int!, $skip: Int!) {
    strapiAwardsPage {
      seo {
        seoTitle
        seoDescription
        noIndex
        noFollow
        excludeFromSitemap
      }
      title
      featuredImage {
        url
        formats {
          large {
            url
          }
        }
      }
    }
    allStrapiNews(
      limit: $limit,
      skip: $skip,
      sort: {fields: publicationDate, order: DESC}
      filter: {news_category: {slug: {eq: "awards"}}, showInArchive: {ne: false}}
    ) {
      edges {
        node {
          slug
          title
          content
          featuredImage {
            url
          }
          publicationDate(formatString: "MMMM D, YYYY")
          link
        }
      }
    }
  }
`
